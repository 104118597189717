import React from "react";

function DrivingSchool() {
  return (
    <div className="drivingschoolcontainer">
      <div className="row w-100 h-100 align-items-center p-2 justify-content-center">
        <div className="col-lg-6 col-12">
          <div>
            <div className="heading-outstation">
              <h5>Driving School Services for Business and Personal Needs</h5>
            </div>
            <div>
              <p>
                <span className="fw-bold">Description:</span>
                Our driving school offers a full range of educational and
                practical driving solutions tailored to both personal and
                business requirements. Whether you’re seeking to improve your
                driving skills, prepare for a license, or equip your team with
                essential driving knowledge, we provide expert instruction and
                flexible options to meet your needs.
              </p>
            </div>
          </div>
          <div>
            <div className="localdesc">
              <p>
                <span className="fw-bold">Tailored Driving Lessons: </span>
                We cater to various driving needs, from personal driver
                education to specialized training for business purposes. Our
                courses are designed to accommodate different skill levels and
                objectives, whether you’re a new driver looking to obtain a
                license or a professional needing advanced driving techniques.
              </p>
            </div>
            <div className="localdesc">
              <p>
                <span className="fw-bold">
                  Experienced and Professional Instructors:
                </span>
                Our team of skilled instructors brings years of experience and
                expertise to every lesson. They are dedicated to providing
                high-quality instruction that emphasizes safety, confidence, and
                practical skills. Each instructor is certified and committed to
                helping you achieve your driving goals.
              </p>
            </div>
            <div className="localdesc">
              <p>
                <span className="fw-bold">Safety First:</span>
                Safety is our top priority. Our driving school adheres to the
                highest safety standards, ensuring that all training is
                conducted in a safe and controlled environment. We use
                well-maintained vehicles equipped with modern safety features to
                provide a secure learning experience.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="imglocal">
            <img
              src="\asessts\Services-images\drivingschool.jpg"
              className="w-75"
              height={"300px"}
              style={{ borderRadius: "10px", maxWidth: "100%" }}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DrivingSchool;
