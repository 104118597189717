import React from "react";
import { useNavigate } from "react-router-dom";

function MobileHeader() {
  const navigate = useNavigate();
  return (
    <div className="mobileheader">
      <div className="nav-mob">
        <div style={{gap:'0.6vh'}} className="d-flex align-items-center">
        <div onClick={()=>navigate('/')}>
            <img src="\asessts\Images\locationround.png" className="img-goody-mob" alt="" />
        </div>
        <div>
            <h5 style={{color:'#FABD12'}} className="mt-1">DriveOme</h5>
          </div>
        </div>
        
        
      </div>
    </div>
  );
}

export default MobileHeader;
