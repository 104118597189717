import React from "react";
import MobileHeader from "./MobileHeader";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  return (
    <div className="header">
      <div className="nav2">
        <div className="d-flex align-items-center">
          <div>
            <img
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
              src="/asessts/Images/locationround.png"
              className="goody-icon"
              alt=""
            />
          </div>
          <div>
            <h5 style={{color:'#FABD12'}}>DriveOme</h5>
          </div>
        </div>

        <div>
          <ul>
            <li>HOME</li>
            <li>OUR STORY</li>
            <li>DELIVER GOODS</li>
            <li>DRIVERS PARTNERS</li>
            <li>CONTACT US</li>
          </ul>
        </div>
      </div>
      <div className="mobile">
        <MobileHeader />
      </div>
    </div>
  );
}

export default Header;
