import React from "react";

function FirstSection() {
  return (
    <div className="firstsection">
      <div className="Reliable">
        <div className="mt-4">
          <h1>Reliable Delivery App</h1>
          <p>
            Book Bike & Truck in 10 Seconds <br />
            Amazingly Low Fare
          </p>
          {/* <div className="btn-goody">
            <button>DELIVER NOW</button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default FirstSection;
