import React from "react";

function AmazingFast() {
  return (
    <div className="amazing-container">
      <div className="amazing-container2">
        <div className="first-amazing">
          <div>
            <img src="\asessts\Images\lightning-bolt.png" alt="" />
          </div>
          <div className="tempo">
            <h3>Amazingly Fast</h3>
            <p>Book Bike & Truck in 10 Seconds, ETA 10 Minutes!</p>
          </div>
        </div>
        <div className="first-amazing">
          <div>
            <img src="\asessts\Images\security.png" height={60} alt="" />
          </div>
          <div className="tempo">
            <h3>AReliable & Professional </h3>
            <p>100% Trained & Verified Driver Partners! </p>
          </div>
        </div>
        <div className="first-amazing">
          <div>
            <img src="\asessts\Images\tempo.png" height={60} alt="" />
          </div>
          <div className="tempo">
            <h3>Bike, Tempo & More </h3>
            <p>B2W, 3W, 7ft, 8ft, 407, Eicher & More Service Types!</p>
          </div>
        </div>
        <div className="first-amazing">
          <div>
            <img src="\asessts\Images\lightning-bolt.png" alt="" />
          </div>
          <div className="tempo">
            <h3>Amazingly Cheap</h3>
            <p>40% Lower Than Market Rate. Reduce Cost Now!</p>
          </div>
        </div>
        <div className="first-amazing">
          <div>
            <img src="\asessts\Images\tracking_1559861.png" height={60} alt="" />
          </div>
          <div className="tempo">
            <h3>Live Tracking</h3>
            <p>Everything Under Your Control in One App!</p>
          </div>
        </div>
        <div className="first-amazing">
          <div>
            <img src="\asessts\Images\gps.png" className="" height={60} alt="" />
          </div>
          <div className="tempo">
            <h3>Local, Outstation & More</h3>
            <p>Logistics Solution at Your Fingertips!</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AmazingFast;
