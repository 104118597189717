import React from "react";

function PackersMovers() {
  return (
    <div className="drivingschoolcontainer">
      <div className="row w-100 h-100 align-items-center p-2 justify-content-center">
        <div className="col-lg-6 col-12">
          <div>
            <div className="heading-outstation">
              <h5>Packers & Movers: Reliable Relocation Services Nationwide</h5>
            </div>
            <div>
              <p>
                <span className="fw-bold">Description:</span>
                Simplify your move with our professional packers and movers
                services! Whether you're relocating across town or across the
                country, our expert team is here to make your transition smooth
                and stress-free. We handle everything from packing and loading
                to transportation and unloading, ensuring your belongings arrive
                safely and on time. Our comprehensive services include secure
                packing materials, efficient logistics, and careful handling of
                your items. With competitive rates and a commitment to customer
                satisfaction, trust us to manage your move with precision and
                care. Enjoy peace of mind during your relocation and let us take
                care of the heavy lifting.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="imglocal">
            <img
              src="\asessts\Services-images\packers&movers.jpg"
              className="w-75"
              height={"300px"}
              style={{ borderRadius: "10px", maxWidth: "100%" }}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackersMovers;
