import React from "react";
import { MdOutlineArrowRight } from "react-icons/md";

function BookBike() {
  return (
    <div className="bookbike-conainer">
      <div className="bookbike-conainer2">
        <div className="headingbook">
          <h1>Book Bike & Truck in 10 Seconds</h1>
        </div>
        <div
          style={{ gap: "3vh" }}
          className="d-flex flex-wrap justify-content-center mt-3 w-100"
        >
          <div className="first">
            <div className="img-book d-flex justify-content-center">
              <img
                src="\asessts\Images\mob.webp"
                className="img-fluid"
                style={{ height: "640px" }}
                alt=""
              />
            </div>
          </div>
          <div className="second">
            <div>
              <div className="mt-4">
                <p className="setup">Live Tracking</p>
                <p className="text-start" style={{ fontSize: "16px" }}>
                  Monitor Delivery Hassle-Freep
                </p>
              </div>
              <div className="mt-4 d-flex">
                <h5
                  style={{
                    borderBottom: "0.6px solid ",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  DOWNLAOD APP NOW
                </h5>
                <MdOutlineArrowRight
                  size={30}
                  style={{ marginBottom: "14px" }}
                  color="#000"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookBike;
