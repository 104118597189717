import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Home from "./components/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { useEffect } from "react";
import Local from "./pages/services/Local";
import Outstation from "./pages/services/Outstation";
import DrivingSchool from "./pages/services/DrivingSchool";
import HireDriver from "./pages/services/HireDriver";
import RentalBike from "./pages/services/RentalBike";
import PackersMovers from "./pages/services/Packers&Movers";

function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null; 
  };
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/local" element={<Local/>} />
          <Route path="/outstation" element={<Outstation/>} />
          <Route path="/drivingschool" element={<DrivingSchool/>} />
          <Route path="/hiredriver" element={<HireDriver/>} />
          <Route path="/rentalbike" element={<RentalBike/>} />
          <Route path="/packersandmovers" element={<PackersMovers/>} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
