import React from 'react'
import FirstSection from '../pages/home/FirstSection'
import GoodyDelivery from '../pages/home/GoodyDelivery'
import AmazingFast from '../pages/home/AmazingFast'
import BookBike from '../pages/home/BookBike'
import AllVan from '../pages/home/AllVan'

function Home() {
  return (
    <div>
        <FirstSection/>
        <GoodyDelivery/>
        <AmazingFast/>
        <BookBike/>
        <AllVan/>
    </div>
  )
}

export default Home