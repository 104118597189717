import React from "react";

function AllVan() {
  return (
    <div className="allvancontainer">
      <div className="headingvan">
        <h1>All Vehicle Types in One App</h1>
      </div>
      <div className="vechile-contianer">
        <div className="vechile-card">
          <div className="img-container">
            <img
              src="\asessts\Images\Wheeler2.png"
              className="img-fluid"
              alt=""
              width={"270px"}
            />
          </div>
          <div className="bike mt-3">
            <h2 style={{ fontWeight: "400", color: "#1B1B1B" }}>2-Wheeler</h2>
          </div>
          <div className="documents mt-3">
            <p className="fw-bold" style={{ color: "#5E5E5E" }}>
              For: Documents, Food, Samples, Parcels, Groceries, Medicines
              Delivery
            </p>
            <p className="capacity">
              Capacity: 20 kg <br />
              <span>Size: 40 x 40 x 40 cm</span>
            </p>
          </div>
        </div>
        <div className="vechile-card">
          <div className="img-container">
            <img
              src="\asessts\Images\threeWheeler.jpg"
              className="img-fluid"
              alt=""
              width={"270px"}
            />
          </div>
          <div className="bike mt-3">
            <h2 style={{ fontWeight: "400", color: "#1B1B1B" }}>3-Wheeler</h2>
          </div>
          <div className="documents mt-3">
            <p className="fw-bold" style={{ color: "#5E5E5E" }}>
              For: Catering, Garment, Packaging, Hardware, Stationery, Gift
            </p>
            <p className="capacity">
              Capacity: 500 kg
              <br />
              <span>Size: 5.5 x 4.5 x 5 ft</span>
            </p>
          </div>
        </div>
        {/* <div className="vechile-card">
          <div className="img-container">
            <img
              src="\asessts\tata.jfif"
              className="img-fluid"
              alt=""
              width={"270px"}
            />
          </div>
          <div className="bike mt-3">
            <h2 style={{ fontWeight: "400", color: "#1B1B1B" }}>Tata Ace</h2>
          </div>
          <div className="documents mt-3">
            <p className="fw-bold" style={{ color: "#5E5E5E" }}>
              For: Documents, Food, Samples, Parcels, Groceries, Medicines
              Delivery
            </p>
            <p className="capacity">
              Capacity: 885 kg <br />
              <span>Size: 380 x 150 x 185 cm</span>
            </p>
          </div>
        </div> */}
        <div className="vechile-card">
          <div className="img-container">
            <img
              src="\asessts\Images\bigTruck.jpg"
              className="img-fluid"
              alt=""
              width={"270px"}
            />
          </div>
          <div className="bike mt-3">
            <h2 style={{ fontWeight: "400", color: "#1B1B1B" }}>4-Wheeler</h2>
          </div>
          <div className="documents mt-3">
            <p className="fw-bold" style={{ color: "#5E5E5E" }}>
              For: Documents, Food, Samples, Parcels, Groceries, Medicines
              Delivery
            </p>
            <p className="capacity">
              Capacity: 31000 kg <br />
              <span>Size: 3995 mm x 1804 mm x 1620 mm</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllVan;
