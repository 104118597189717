import React from "react";

function Local() {
  return (
    <div className="localcontainer">
      <div  className="row w-100 h-100 align-items-center p-2 localrow">
        <div className="col-lg-6 col-12">
          <div>
            <div className="localheading">
              <h5>Comprehensive Local Moving Services</h5>
            </div>
            <div className="localdesc">
              <p>
                Whether you're relocating your home or business, we offer
                reliable solutions for moving goods and shifting houses. From
                delivering anything you need to anytime, anywhere, our services
                are designed to meet all your local moving needs efficiently and
                effectively.
              </p>
            </div>
          </div>
          <div>
            <div className="localdesc">
              <p>
                <span className="fw-bold">
                  Flexible Scheduling and Timings:
                </span>{" "}
                We understand that timing is crucial when it comes to moving.
                That's why we offer flexible scheduling options to fit your
                needs. Whether you're moving on a weekday, weekend, or need
                last-minute changes, we work around your schedule to make the
                process as convenient as possible.
              </p>
            </div>
            {/* <div className="localdesc">
              <p>
                <span className="fw-bold">Comprehensive Moving Solutions:</span>{" "}
                Our services cover everything from residential and commercial
                moves to special deliveries. Whether you need to relocate your
                entire household, shift office equipment, or have a unique
                delivery requirement, we have the expertise and resources to
                handle it all. No job is too big or too small for our
                experienced team.
              </p>
            </div> */}
            {/* <div className="localdesc">
              <p>
                <span className="fw-bold">
                  Reliable and Efficient Service:{" "}
                </span>
                Efficiency and reliability are at the core of our operations.
                Our professional team is committed to providing prompt and
                dependable service, ensuring that your items are transported
                safely and on time. We use well-maintained vehicles and advanced
                tools to ensure a smooth and secure move.
              </p>
            </div> */}
            <div className="localdesc">
              <p>
                <span className="fw-bold">
                  Experienced and Professional Team:
                </span>
                Our team of skilled movers and drivers brings years of
                experience to every job. Trained to handle a wide range of
                moving challenges, we approach each task with professionalism
                and a keen eye for detail. Your belongings are treated with the
                highest level of care, ensuring they arrive at their destination
                in perfect condition.
              </p>
            </div>
            {/* <div className="localdesc">
              <p>
                <span className="fw-bold">
                  Affordable and Transparent Pricing:
                </span>
                We believe in offering high-quality services at competitive
                prices. Our transparent pricing structure means there are no
                hidden fees or unexpected charges. We provide clear and upfront
                estimates, allowing you to budget effectively for your move.
              </p>
            </div> */}
            <div className="localdesc">
              <p>
                <span className="fw-bold">Safety and Security:</span>
                The safety of your items is our top priority. We employ rigorous
                safety protocols and use high-quality packing materials to
                protect your belongings during transit. Our team takes every
                precaution to ensure that your move is smooth and your items are
                well-protected.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="imglocal">
            <img
              src="\asessts\Services-images\movers.jpeg"
              className=""
              height={"300px"}
              style={{ borderRadius: "10px" ,maxWidth:'100%'}}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Local;
