import React from "react";
import { useNavigate } from "react-router-dom";

function GoodyDelivery() {
  const navigate = useNavigate();
  return (
    <div className="goody-container">
      <div className="heading-goody">
        <h1>DriveOme</h1>
        <p className="text-center">
          Your goods delivery partner in your doorstep
        </p>
      </div>
      <div className="service-heading">
        <h5>Services</h5>
      </div>
      <div className="goody-cardcontianer">
        <div onClick={() => navigate("/local")} className="goody-card">
          <div className="img-container">
            <img
              src="\asessts\Images\cr=w_730,h_549.webp"
              className="img-fluid"
              alt=""
              width={"270px"}
              height={"240px"}
            />
          </div>
          <div className="local">
            <h2 style={{ fontWeight: "400", color: "#1B1B1B" }}>Local</h2>
          </div>
          <div className="business">
            <p>
              Business & Personal, Move Goods & Shift House, Deliver Anything,
              Anytime, Anywhere.
            </p>
          </div>
        </div>
        <div
          className="goody-card"
          onClick={() => navigate("/packersandmovers")}
        >
          <div className="img-container">
            <img
              src="\asessts\Images\rental.avif"
              alt=""
              style={{ maxWidth: "100%" }}
              height={"200px"}
            />
          </div>
          <div className="local">
            <h2 style={{ fontWeight: "400", color: "#1B1B1B" }}>
              Packers & Movers
            </h2>
          </div>
          <div className="business">
            <p>
              Hourly & Daily Rental Packages in One-Click. Amazingly Low Fare!
            </p>
          </div>
        </div>
        <div className="goody-card" onClick={() => navigate("/outstation")}>
          <div className="img-container">
            <img
              src="\asessts\Images\Outstation.webp"
              className="img-fluid"
              alt=""
              width={"270px"}
            />
          </div>
          <div className="local">
            <h2 style={{ fontWeight: "400", color: "#1B1B1B" }}>Outstation</h2>
          </div>
          <div className="business">
            <p>
              Pan India Full Truck Transport Services. Hire Tata Ace, 407,
              Eicher, Trailer, Taurus.
            </p>
          </div>
        </div>
      </div>
      <div className="otherservice-heading">
        <h5>Other Services</h5>
      </div>
      <div className="goody-cardcontianer">
        <div className="goody-card" onClick={() => navigate("/drivingschool")}>
          <div className="img-container">
            <img
              src="\asessts\Images\Driving-School.jpg"
              className="img-fluid"
              alt=""
              height={"200px"}
            />
          </div>
          <div className="local">
            <h2 style={{ fontWeight: "400", color: "#1B1B1B" }}>
              Driving School
            </h2>
          </div>
          <div className="business">
            <p>
              Business & Personal, Move Goods & Shift House, Deliver Anything,
              Anytime, Anywhere.
            </p>
          </div>
        </div>
        <div className="goody-card" onClick={() => navigate("/hiredriver")}>
          <div className="img-container">
            <img
              src="\asessts\Images\hiredriver.jfif"
              alt=""
              style={{ maxWidth: "100%" }}
              height={"200px"}
            />
          </div>
          <div className="local">
            <h2 style={{ fontWeight: "400", color: "#1B1B1B" }}>Hire Driver</h2>
          </div>
          <div className="business">
            <p>
              Hourly & Daily Rental Packages in One-Click. Amazingly Low Fare!
            </p>
          </div>
        </div>
        <div className="goody-card" onClick={() => navigate("/rentalbike")}>
          <div className="img-container">
            <img
              src="\asessts\Images\bike.webp"
              className="img-fluid"
              alt=""
              height={"200px"}
            />
          </div>
          <div className="local">
            <h2 style={{ fontWeight: "400", color: "#1B1B1B" }}>Rental Bike</h2>
          </div>
          <div className="business">
            <p>
              Pan India Full Truck Transport Services. Hire Tata Ace, 407,
              Eicher, Trailer, Taurus.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoodyDelivery;
