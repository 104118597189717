import React from "react";

function HireDriver() {
  return (
    <div className="drivingschoolcontainer">
      <div className="row w-100 h-100 align-items-center p-2 justify-content-center">
        <div className="col-lg-6 col-12">
          <div>
            <div className="heading-outstation">
              <h5>Hire a Driver: Hourly & Daily Rental Packages</h5>
            </div>
            <div>
              <p>
                <span className="fw-bold">Description:</span>
                Unlock effortless travel with our expertly designed driver
                rental packages! Whether you need a driver for just a few hours
                or an entire day, our flexible options cater to all your needs.
                Enjoy the freedom of traveling in comfort and style without the
                hassle of driving. Our competitive rates ensure you get amazing
                value for your money, with prices that won’t break the bank.
                Booking is a breeze with our easy one-click system, allowing you
                to arrange your transportation quickly and efficiently. Say
                goodbye to the stress of navigation and parking—let our
                professional drivers handle the details while you sit back and
                relax. Choose our hourly or daily rental packages for a smooth,
                enjoyable travel experience at an unbelievably low fare.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="imglocal">
            <img
              src="\asessts\Services-images\hire-driver.jpg"
              className="w-75"
              height={"300px"}
              style={{ borderRadius: "10px", maxWidth: "100%" }}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HireDriver;
