import React from "react";

function RentalBike() {
  return (
    <div className="drivingschoolcontainer">
      <div className="row w-100 h-100 align-items-center p-2 justify-content-center">
        <div className="col-lg-6 col-12">
          <div>
            <div className="heading-outstation">
              <h5>Rental Bike: Convenient & Affordable Options Nationwide</h5>
            </div>
            <div>
              <p>
                <span className="fw-bold">Description:</span>
                Rev up your travel plans with our extensive range of rental
                bikes, available across India! Whether you're exploring a new
                city, commuting daily, or just need a reliable ride for a short
                period, we have the perfect bike for you. Choose from a variety
                of models to suit your preferences and needs, all at incredibly
                competitive rates. Our easy booking process and well-maintained
                bikes ensure a smooth and enjoyable riding experience. From city
                streets to scenic routes, rent a bike and enjoy the freedom to
                go wherever you please with comfort and style.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="imglocal">
            <img
              src="\asessts\Services-images\rental-bike.webp"
              className="w-75"
              height={"300px"}
              style={{ borderRadius: "10px", maxWidth: "100%" }}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RentalBike;
