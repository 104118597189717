import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div style={{ padding: '50px 100px' }}>
            <h2>Privacy-Policy</h2>
            <div>
                1. <span style={{ fontWeight: '700' }}>Introduction:</span> This Privacy Policy explains how we collects, uses, and shares information about you when you use our Goods delivery app ("DriveOme"). By using the App, you agree to the collection and use of your information in accordance with this policy.
            </div>
            <div style={{ marginTop: '10px' }}>
                2. <span style={{ fontWeight: '700' }}> Information We Collect Personal Information</span>: We may collect your name, email address, phone number, delivery address, payment information, and other details required to process your orders.
                Usage Data: We collect information about how you interact with the App, such as your IP address, device information, and browsing history.
                Location Data: With your consent, we may collect and use your location data to provide delivery services.
            </div>
            <div style={{ marginTop: '10px' }}>
                3. <span style={{ fontWeight: '700' }}> How We Use Your Information We use the information we collect to</span>:
                Process and deliver your orders.
                Communicate with you about your orders and provide customer support.
                Improve and personalize your experience with the App.
                Send you promotional offers and updates, with your consent.
            </div>
            <div style={{ marginTop: '10px' }}>
                4. <span style={{ fontWeight: '700' }}> Sharing Your Information We may share your information with</span>:

                Service Providers: Third-party companies that help us operate the App, process payments, and deliver orders.
                Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction.
                Legal Requirements: We may disclose your information if required by law or in response to legal processes.
                <div style={{ marginTop: '10px' }}>
                    5. <span style={{ fontWeight: '700' }}>Data Security</span>: We implement appropriate security measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure.

                </div>
                <div style={{ marginTop: '10px' }}>
                    6. <span style={{ fontWeight: '700' }}>Your Rights :</span> You have the right to access, update, or delete your personal information. You can do this by contacting us through the App or by emailing DriveOme@gmail.com.
                </div>
            </div>
        </div >
    )
}

export default PrivacyPolicy