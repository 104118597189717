import React from "react";

function Outstation() {
  return (
    <div className="outstaitoncontainer">
      <div className="row w-100 h-100 align-items-center p-2 justify-content-center">
        <div className="col-lg-6 col-12">
          <div>
            <div className="heading-outstation">
              <h5>Nationwide Full Truck Transport Solutions</h5>
            </div>
            <div>
              <p>
                <span className="fw-bold">Description:</span>
                Expand your reach with our comprehensive outstation transport
                services across India. We offer reliable full truck transport
                options, including Tata Ace, 407, Eicher, Trailer, and Taurus
                vehicles, to cater to all your logistics needs. Whether you're
                moving goods across cities or states, our fleet is equipped to
                handle diverse cargo efficiently. Trust us for secure, timely,
                and cost-effective transportation solutions tailored to your
                business requirements.
              </p>
            </div>
          </div>
          <div>
            <div className="localdesc">
              <p>
                <span className="fw-bold">Extensive Fleet of Vehicles: </span>
                To accommodate a wide range of cargo requirements, we offer a
                diverse fleet including Tata Ace, 407, Eicher, Trailer, and
                Taurus vehicles. Whether you need a small, agile truck for local
                deliveries or a larger trailer for bulk shipments, we have the
                right vehicle to meet your needs. Each vehicle is maintained to
                high standards to ensure reliable performance.
              </p>
            </div>
            <div className="localdesc">
              <p>
                <span className="fw-bold">
                  Flexible and Customizable Solutions:
                </span>
                Understanding that each business has unique needs, we offer
                customizable transport solutions tailored to your specific
                requirements. Whether you need regular shipments, one-off
                deliveries, or specialized transport, we provide flexible
                options to suit your logistics strategy.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="imglocal">
            <img
              src="\asessts\Services-images\outstation2.jfif"
              className="w-75"
              height={"300px"}
              style={{ borderRadius: "10px", maxWidth: "100%" }}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Outstation;
